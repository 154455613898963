export enum OGTypes {
    Article = 'article',
    Website = 'website',
}

export interface Props {
    title: string;
    titleSuffix?: string;
    description?: string;
    keywords?: string;
    tags?: string;
    children?: any;
    canonical?: string;
    robots?: string;
    ogType?: OGTypes;
    ogImage?: string;
    cmsPageIndexingEnabled?: boolean;
    pageRelations?: {
        nextRel: string;
        prevRel: string;
    }
}
