import ApiArticle from 'types/api/Article';
import { ApiResources } from 'types/apiResources';
import { Endpoints } from 'types/endpoints';
import Element from 'types/redux/Element';
import ListCollection from 'types/redux/ListCollection';

import Article from 'models/Article';

import { ELEMENT_MARKUP, LIST_MARKUP } from 'consts/redux';
import { withVariables } from 'utils/string';
import { request } from 'services/Api';

export interface ListParams {
    page?: number;
    perPage?: number;
    categorySlug?: string;
    recommended?: boolean;
    search?: string;
    gender?: string;
    genderOptional?: boolean;
    resource?: 'simple' | null;
    productId?: string;
    articleRelatedId?: string;
    bundleId?: string;
}

export const list = async (params?: ListParams): Promise<ListCollection<Article>> => {
    const response = await request({
        method: 'GET',
        path: withVariables(Endpoints.PublicArticles, params),
        requestParams: params,
        params,
    });

    const data = response?.payload?.data;
    const resource = data && data[ApiResources.Articles];

    if (!data || !resource) {
        throw new Error('Not Found');
    }

    return {
        ...LIST_MARKUP,
        isLoaded: true,
        elements: resource
            .elements
            .map((element: ApiArticle) => new Article(element)),
        meta: resource.meta,
    };
};

export const listByCategories = async (params?: ListParams): Promise<ListCollection<Article>> => {
    const response = await request({
        method: 'GET',
        path: withVariables(Endpoints.PublicArticlesByCategories, params),
        requestParams: params,
        params,
    });

    const data = response?.payload?.data;
    const resource = data && data[ApiResources.Articles];

    if (!data || !resource) {
        throw new Error('Not Found');
    }

    return {
        ...LIST_MARKUP,
        isLoaded: true,
        elements: resource
            .elements
            .map((element: ApiArticle) => new Article(element)),
        meta: resource.meta,
    };
};

export interface SingleParams {
    slug: string;
    limitProducts?: number;
}
export const single = async (params: SingleParams): Promise<Element<Article>> => {
    const response = await request({
        method: 'GET',
        path: withVariables(Endpoints.PublicArticle, params),
        requestParams: params,
        params,
    });

    const data = response?.payload?.data;
    const resource: ApiArticle = data && data[ApiResources.Article];

    if (!data || !resource) {
        throw new Error('Not Found');
    }

    return {
        ...ELEMENT_MARKUP,
        isLoaded: true,
        data: new Article(resource),
    };
};

export interface SubmitRatingParams {
    id: string;
    rating: number;
}

export const submitRating = async (params: SubmitRatingParams): Promise<Element<Article>> => {
    const response = await request({
        method: 'POST',
        path: withVariables(Endpoints.PublicArticleRating, params),
        requestParams: params,
        params,
    });

    const data = response?.payload?.data;
    const resource: ApiArticle = data && data[ApiResources.Article];

    if (!data || !resource) {
        throw new Error('Not Found');
    }

    return {
        ...ELEMENT_MARKUP,
        isLoaded: true,
        data: new Article(resource),
    };
};
