import Component from './component';
import { OGTypes } from './types';

Component.defaultProps = {
    titleSuffix: '',
    description: null,
    keywords: null,
    tags: null,
    children: null,
    canonical: null,
    ogType: OGTypes.Website,
    ogImage: null,
    robots: 'index, follow',
    cmsPageIndexingEnabled: false,
};
export default Component;
